




















import {
  computed, defineComponent, inject, PropType,
} from '@nuxtjs/composition-api';
import { SfColor, SfHeading } from '@storefront-ui/vue';
import type { Aggregation } from '~/modules/GraphQL/types';
import {useUrl} from "~/composables/Palmers";

export default defineComponent({
  components: {
    SfColor,
    SfHeading,
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true,
    },
  },
  setup() {
    const {getSwatchUrl} = useUrl();
    const { isFilterSelected } = inject('UseFiltersProvider');
    const selected = computed(() => ((id: string, optVal: string) => isFilterSelected(id, optVal)));
    return {
      selected,
      getSwatchUrl
    };
  },
  methods: {
    getSwatchValue(swatchValue) {
      if(swatchValue?.value.indexOf('/') > -1) {
        return `url('${this.getSwatchUrl(swatchValue?.value)}')`
      }
      return swatchValue?.value;
    }
  }
});
